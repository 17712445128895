@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.div-area {
    font-family: 'Open Sans', sans-serif;
    color: white;
    background-color: rgb(40,40,40);
}
#result-area {
    height: 30vh;
    font-size: 50pt;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 5%;
}
table {
    width: 100%;
    height: 70vh;
    padding-bottom: 5vh;
}
td {
    background-color: rgba(30,30,30);
    width: 25%;
    text-align: center;
    font-size: 28px;
    outline:1px rgb(40,40,40) solid;
}
td:hover {
    cursor: pointer;
    background-color: rgb(50,50,50);
}
.opperator {
    background-color: rgb(35, 35 , 35);
}
#equals {
    background-color: rgba(255, 155, 55, 0.9);
}